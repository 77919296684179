import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

const Var = ({
  data: { allPrismicPost }
}: {
  data: {
    allPrismicPost: {
      nodes: [{ data: { images: [{ image: PrismicImage }] } }];
    };
  };
}) => {
  return (
    <div style={{ marginRight: "2rem", marginBottom: 64}}>
      <div
        style={{
          display: "flex",
          width: "75%",
          flexWrap: "wrap",
          justifyContent: "flex-start"
        }}
      >
        {allPrismicPost.nodes.map((node) =>
          node.data.images.map(({ image }) => (
            <Image
              width={image.dimensions.width}
              height={image.dimensions.height}
              key={image.alt}
              src={image.url.split("?")[0]}
              alt={image.alt}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default Var;
export const VarQuery = graphql`
  query images {
    allPrismicPost {
      nodes {
        data {
          images {
            image {
              alt
              dimensions {
                width
                height
              }
              url
            }
          }
        }
      }
    }
  }
`;
const Image = styled.img`
  padding: ${(props) => (props.width === 64 ? 16 : 43)}px;
  margin: ${(props) => (props.width === 64 ? 8 : 8)}px;
  border-style: double;
  box-shadow: 0 0 6px #c8c8c8, inset 0 0 6px #c8c8c8;
`;
